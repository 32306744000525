.attachment_pdf{
  content: url(../../icons/pdf-attachment.svg);
} 
.attachment_doc{
  content: url(../../icons/doc-attachment.svg);
} 
.attachment_docx{
  content: url(../../icons/doc-attachment.svg);
} 
.attachment_xls{
  content: url(../../icons/xls-attachment.svg);
} 
.attachment_xlsx{
  content: url(../../icons/xls-attachment.svg);
} 
.attachment_mp4{
  content: url(../../icons/video-attachment.svg);
}   
.attachment_mov{
  content: url(../../icons/video-attachment.svg);
}      
.attachment_csv{
  content: url(../../icons/csv-attachment.svg);
} 
.attachment_ppt{
  content: url(../../icons/ppt-attachment.svg);
} 
.attachment_pptx{
  content: url(../../icons/ppt-attachment.svg);
} 
.attachment_txt{
  content: url(../../icons/txt-attachment.svg);
} 
.attachment_default{
  content: url(../../icons/default-attachment.svg);
} 

.attachmentSvg{
  color: #444;
  font-size: 0.9rem;
  background-color: #E7F1FA;
  padding: 1.5rem;
  width: 5rem;
  height: 5rem;
}
 
.attachemntCaption{
  word-break: break-all;
  display: flex;
  text-align: center;
} 

.attachmentContainer{
  cursor: pointer;
  margin: 0.5rem;
  width: 5rem;
}
 