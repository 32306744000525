.commentBox {
    padding: 1rem;
}

.commentButton {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 1rem;
}

.fileUplaod {
    padding-top: 1rem;
}

.formError {
    font-size: 0.75rem;
    color: #da1e28;
    margin-top: 1rem;
  }

.commentButton button {
    padding-right: 1rem;
}

.commentCheckBox {
    display: flex;
    gap: 1rem;
}

.postButton {
    display: flex;
    justify-content: flex-end;
}
