.CustomerEmailInputRow {
  margin-bottom: 1rem;
}

.inputRow {
  display: flex;
  align-items: flex-start;
}

.inputRow :global(.bx--list-box__selection) {
  display: none;
}

.inputRow > :first-child {
  flex-grow: 1;
}

.inputRow > .removeBtn {
  height: 2.5rem;
  margin-inline: 0.5rem;
}
