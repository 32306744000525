.idea {
  background-color: #fafafa;
  border: 1px solid #eee;
  display: grid;
  grid-template-columns: 1fr 300px;
}

.idea p {
  margin-bottom: 1rem;
}

.content {
  padding: 1.5rem;
  max-width: 800px;
}

.product {
  margin-bottom: 1rem;
  color: #444;
  font-size: 0.9rem;
}

.product a {
  text-decoration: none;
  color: #222;
}

.ideaName {
  font-size: 1.75rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.meta {
  --gap: 0.75rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--gap);
  margin-bottom: 1.5rem;
  color: #444;
  font-size: 0.9rem;
  background-color: #f4f4f4;
  padding: 0.5rem 1rem;
}

.workflow {
  display: inline-block;
  flex-shrink: 0;
  border: 1px solid #eee;
  background-color: #eee;
  border-radius: 100px;
  padding: 2px 10px;
}

.idea .workflow button {
  border-bottom: none;
  font-size: inherit;
  color: inherit;
}

.meta > :not(:last-child)::after {
  margin-left: var(--gap);
  content: '·';
  opacity: 0.5;
}

.meta svg {
  vertical-align: -3px;
  margin-right: 2px;
}

.content section b {
  font-weight: 500;
}
.content section i {
  font-style: italic;
}

.content section:not(:last-child) {
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
  margin-bottom: 2rem;
}

.idea h3 {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.sidebar {
  background-color: #f4f4f4;
  padding: 1.5rem;
}

.sidebarLinks {
  margin-bottom: 1.5rem;
}

.sidebarLink,
.actionButtons {
  width: 100%;
  margin-bottom: 0.5rem;
}

.actionButtons {
  display: flex;
  gap: 0.5rem;
}

.sidebar .field {
  margin-bottom: 1.5rem;
}

.sidebar .label {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.issueTrackerFields {
  border-top: 1px solid #ddd;
  padding-top: 1rem;
}

.managedTagContainer {
  margin-bottom: 0.5rem;
}

.visibilityIcon {
  margin-right: 0.2rem;
  margin-bottom: 16px !important;
  align-items: center;
}

.singleIdeaHeader {
  display: flex;
  column-gap: 0.5rem;
}
.description {
  padding-left: 2rem;
  overflow-x: auto;
}

.description pre {
  white-space: normal;
  line-height: normal;
  width: 100%;
  margin-top: 1rem;
  background: #c7c7c74d;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  padding: 0.5em;
}

:global(.todo-list) {
  list-style: none !important;
  margin-left: -1.5rem;
}

:global(.todo-list) label input {
  margin-right: 0.5rem;
}

.description ol {
  list-style-type: decimal;
}

.description ul {
  list-style-type: disc;
}

.description ul,
.description ol {
  margin-bottom: 1rem;
}

.description li {
  margin-bottom: 0.25rem;
}

.description table,
.description td,
.description th {
  border: 1px solid;
  padding: 0.5rem;
}
.description table {
  table-layout: auto;
  width: 100%;
}

p[data-callout-box-type='infoBlock'],
p[data-callout-box-type='noteBlock'],
p[data-callout-box-type='warningBlock'],
p[data-callout-box-type='tipBlock'] {
  padding: 0.5rem;
  border: 2px solid;
  border-radius: 10px;
}

p[data-callout-box-type='infoBlock'] {
  background-color: #dbefff;
  border-color: #b1d4f5;
}
p[data-callout-box-type='noteBlock'] {
  background-color: #f8ffbf;
  border-color: #f0ca4d;
}
p[data-callout-box-type='warningBlock'] {
  background-color: #fae7e1;
  border-color: #fac0af;
}
p[data-callout-box-type='tipBlock'] {
  background-color: #e8f2d9;
  border-color: #7bba34;
}

p[data-callout-box-type='infoBlock']::before {
  content: url(../../icons/information.svg);
}
p[data-callout-box-type='noteBlock']::before {
  content: url(../../icons/pen.svg);
}
p[data-callout-box-type='warningBlock']::before {
  content: url(../../icons/warning--alt.svg);
}
p[data-callout-box-type='tipBlock']::before {
  content: url(../../icons/magic-wand.svg);
}

img {
  max-width: 100%;
  height: auto;
}

.mergedMessage {
  width: 100%;
  max-width: 100%;
}

.mergedMessage p {
  margin-bottom: 0rem;
}

.attachmentRow{
  display: ruby;
}

.label {
  display: block;
}

.formError {
  font-size: 0.75rem;
  color: #da1e28;
  margin-top: 1rem;
}
.richTextEditorWrapper {
  margin-bottom: 1rem;
}

.richTextEditor.invalid {
  outline: 2px solid #da1e28;
  outline-offset: -2px;
}
